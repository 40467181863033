import React from "react"

import { List, ListItem, TextLink } from "@kiwicom/orbit-components"
import Stack from "@kiwicom/orbit-components/lib/Stack"
import Text from "@kiwicom/orbit-components/lib/Text"

import Container from "components/container"
import { EmberCard, EmberCardSection } from "components/generic/ember-card"
import Hero from "components/hero"
import { DriveLayout } from "components/layout-custom"

const Page = () => (
  <DriveLayout title="Shift Allocation & Preferences">
    <Hero title="Shift Allocation & Preferences" />
    <Container size="medium">
      <Stack spacing="XLarge">
        <EmberCard title="Driver Expectations">
          <EmberCardSection>
            <Stack>
              <Text>
                We operate a 24/7 timetable and this means we have shifts spread
                across the entire day. We define our shifts as follows:
              </Text>
              <List>
                <ListItem>
                  Early Shift: Start before 10am and finish before 7pm
                </ListItem>
                <ListItem>
                  Late Shift: Start after midday and finish after 8pm
                </ListItem>
                <ListItem>
                  Mid Shift: Any shift that doesn't fit in the above e.g.
                  10:30am to 9pm
                </ListItem>
              </List>
              <Text>Our expectation of all drivers is that they are</Text>
              <List>
                <ListItem>
                  Trained, able and comfortable driving both the E1 (Dundee -
                  Edinburgh) and the E3 (Dundee - Glasgow)
                </ListItem>
                <ListItem>
                  Able to work a mixed range of shift times (see note below on
                  early and late preference)
                </ListItem>
              </List>
            </Stack>
          </EmberCardSection>
        </EmberCard>
        <EmberCard title="How Shift Allocation Works">
          <EmberCardSection>
            <Stack>
              <Text>
                We publish the rota on driver hub so that you can always see 4
                to 5 weeks ahead. That means you will know your days off and can
                see the details of the shifts you’re working. The rota always
                rolls forward on a Sunday at midnight and then shows you the
                next week ahead.
              </Text>
              <Text>
                This is why you have to request holiday 6 weeks in advance - so
                we have time to reallocate shifts and give us the best chance of
                approving your holiday in line with our policy.
              </Text>
              <Text>
                We monitor things like the number of full weekends off and will
                try to balance them so that it’s equitable amongst all drivers.
              </Text>
              <Text>
                When allocating shifts, we will try to provide blocks of early
                or late shifts so that you’re not flipping between the two. When
                you are switching from a block of earlier to a block of lates we
                will always try to provide an extended break between them. At a
                minimum we will always provide a legal rest.
              </Text>
            </Stack>
          </EmberCardSection>
        </EmberCard>
        <EmberCard title="Early and Late Preferences">
          <EmberCardSection>
            <Stack>
              <Text>
                We will try to accommodate your preference in terms of shift
                timings. You can have a preference for early, late, or a mix.
                Having a preference means that we will try to give you more of
                this shift type - it does not mean that you will only have your
                preference type.{" "}
              </Text>
              <Text>
                Practically, that means if you prefer lates then we will try to
                give you more late shifts than a standard mixed rota. If you are
                the only driver with a late preference then it’s likely you’ll
                get many more late shifts. However, even a single driver with a
                late shift preference should expect to work some other shift
                times.
              </Text>
              <Text>
                Please submit your preference for shift types using this{" "}
                <TextLink href="https://notionforms.io/forms/shift-preference-requests">
                  form.
                </TextLink>
              </Text>
              <Text>
                By submitting your preference using the form above, we will look
                to accommodate it as much as possible when building the future
                rota. This will depend on the entire teams shift preferences and
                it may change as more drivers select their preference. We will
                aim to balance everybody’s preferences equally.
              </Text>
              <Text>
                It’s also ok for your preference to change over time. Maybe you
                prefer a mix now but then decide you want an early preference.
                To register your new preference please submit a new form to make
                us aware and we will look to include it.{" "}
              </Text>
              <Text>
                We are not able to accommodate other preference types, such as
                specific days, or guarantee that you will only be on one shift
                type, such as lates.{" "}
              </Text>
            </Stack>
          </EmberCardSection>
        </EmberCard>
      </Stack>
    </Container>
  </DriveLayout>
)

export default Page
